* {
  box-sizing: border-box;
}

body {
  color: var(--body-color);
  background-color: var(--body-bg-color);
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
}

a {
  color: var(--link-color);
  text-decoration: none;

  &:hover,
  &:focus {
    color: var(--link-hover-color);
    text-decoration: underline;
  }
}

p {
  margin-top: 0;
  margin-bottom: var(--spacer);
}

ul, ol, dl {
  margin-top: 0;
  margin-bottom: var(--spacer);
}

blockquote {
  padding: 0 var(--spacer);
  margin: 0 0 var(--spacer) 0;
  border-left: .25em solid var(--border-color);
  opacity: .65;
}

figure {
  margin: 0;
}

figcaption {
  margin-bottom: var(--spacer);
  font-size: .85em;
}

img {
  display: block;
  max-width: 100%;
  margin-top: 0;
  margin-bottom: var(--spacer);
  border-radius: var(--border-radius);
}

code,
pre {
  font-family: var(--code-font-family);
}

code {
  padding: .1em .25em;
  background-color: var(--code-bg-color);
  border-radius: var(--border-radius);
}

pre {
  display: block;
  overflow: auto;
  padding: var(--spacer);
  margin: var(--spacer) 0;

  code {
    padding: 0;
    background-color: inherit;
  }
}

table {
  margin-top: 0;
  margin-bottom: var(--spacer);
  width: 100%;
  border: 0 solid var(--border-color);
  border-collapse: collapse;
}

td,
th {
  padding: .25em .5em;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
}

th {
  text-align: left;
}

thead th {
  border-bottom-color: currentColor;
}

mark {
  padding: .15em;
  border-radius: var(--border-radius);
  color: var(--body-bg-color);
  background-color: var(--info-color);
}

hr {
  position: relative;
  margin: var(--spacer-2) 0;
  border: 0;
  border-top: 1px solid var(--border-color);
}

abbr {
  font-weight: bold;
  text-transform: uppercase;

  &[title] {
    cursor: help;
    border-bottom: 1px dotted var(--border-color);
  }
}
